import Footer from "./sub/footer";
import Navigation from "./sub/navigation";

const Service = () => {
  return (
    <>
      <Navigation service="active" />

      <main id="main">
        <div className="breadcrumbs">
          <div
            className="page-header d-flex align-items-center"
            style={{ "background-image": "url(assets/img/service.jpg)" }}
          >
            <div className="container position-relative">
              <div className="row d-flex justify-content-center">
                <div className="col-lg-6 text-center">
                  <h2>Services</h2>
                  <p>
                    Premium IT Consultants works with reputed corporations
                    around the world to identify their needs for Information
                    Technology professionals
                  </p>
                </div>
              </div>
            </div>
          </div>
          <nav>
            <div className="container">
              <ol>
                <li>
                  <a href="index.html">Home</a>
                </li>
                <li>Services</li>
              </ol>
            </div>
          </nav>
        </div>

        <section id="service" className="services pt-0">
          <div className="container" data-aos="fade-up">
            <div className="section-header">
              <span>Our Services</span>
              <h2>Our Services</h2>
            </div>
          </div>
        </section>

        <section id="features" className="features">
          <div className="container">
            <div
              className="row gy-4 align-items-center features-item"
              data-aos="fade-up"
            >
              <div className="col-md-5">
                <img
                  src="assets/img/strategy.jpg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-md-7">
                <h3>IT Strategy Consultancy</h3>
                <p className="fst-italic">
                  Premium IT Consultants consulting services covers the
                  following: Strategic consultancy, where our professionals
                  guide corporations to identify right technology and
                  implementation those technologies in a cost-effective manner.
                  Our expertise covers the following industries:
                </p>
                <ul>
                  <li>
                    <i className="bi bi-check"></i> IT
                  </li>
                  <li>
                    <i className="bi bi-check"></i> Telecommunication
                  </li>
                  <li>
                    <i className="bi bi-check"></i> Financial Services
                  </li>
                  <li>
                    <i className="bi bi-check"></i> Automotive
                  </li>
                  <li>
                    <i className="bi bi-check"></i> Renewable Energy
                  </li>
                </ul>
              </div>
            </div>

            <div
              className="row gy-4 align-items-center features-item"
              data-aos="fade-up"
            >
              <div className="col-md-5 order-1 order-md-2">
                <img
                  src="assets/img/security.jpg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-md-7 order-2 order-md-1">
                <h3>IT Security Services:</h3>
                <p className="fst-italic">
                  There are different reasons why Premium IT Consultants are
                  called in:
                </p>
                <ul>
                  <li>
                    <i className="bi bi-check"></i>To gain external, objective
                    advice and recommendations
                  </li>
                  <li>
                    <i className="bi bi-check"></i> To gain access to our
                    consultants' specialized expertise
                  </li>
                  <li>
                    <i className="bi bi-check"></i> Temporary help during a
                    one-time project where the hiring of a permanent employee(s)
                    is not required or necessary
                  </li>
                  <li>
                    <i className="bi bi-check"></i>To outsource all or part of
                    the IT services from a specific company.
                  </li>
                </ul>
              </div>
            </div>

            <div
              className="row gy-4 align-items-center features-item"
              data-aos="fade-up"
            >
              <div className="col-md-5">
                <img
                  src="assets/img/management.jpg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-md-7">
                <h3>IT Managed Services</h3>
                <p className="fst-italic">
                  IT Managed services is the practice of outsourcing on a
                  proactive basis certain information technology processes and
                  functions intended to improve operations and cut expenses. It
                  is an alternative to the break/fix or on-demand outsourcing
                  model where the service provider performs on-demand services
                  and bills the customer only for the work done. Managed
                  Services allows a business to offload IT operations to Premium
                  IT Consultants. We assume ongoing responsibility for 24-hour
                  monitoring, managing and/or problem resolution for the IT
                  systems within a JH business, allowing business to focus on
                  their core operations.
                </p>
              </div>
            </div>

            <div
              className="row gy-4 align-items-center features-item"
              data-aos="fade-up"
            >
              <div className="col-md-5 order-1 order-md-2">
                <img
                  src="assets/img/workplace.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-md-7 order-2 order-md-1">
                <h3>Workforce Management</h3>
                <p className="fst-italic">
                  Premium IT Consultants works with reputed corporations around
                  the world to identify their needs for Information Technology
                  professionals. Premium IT Consultants recognizes that
                  irrespective of the size of the company, workforce management
                  is a challenge. Placing the right staff member in the right
                  location at the right time and at the right price is complex
                  and often seen as a daunting task to undertake. Identifying
                  talents that’s right fit for an organization is a constant
                  challenge faced by organizations around the world. We have
                  onshore and offshore recruitment teams that identifies, assess
                  and recruit IT talents. We then take these talents and train
                  them in our own training centres in the countries where we
                  operate and take them through rigorous retraining and
                  assessment process. The objective is to retrain these talents
                  according to the specific needs of our customers. The result
                  is less time in integrating ourtalents into the company
                  specific ecosystems, which can yield substantial cost-savings
                  opportunities while improving staff engagement and
                  utilization.
                </p>
              </div>
            </div>

            <div
              className="row gy-4 align-items-center features-item"
              data-aos="fade-up"
            >
              <div className="col-md-5">
                <img
                  src="assets/img/software.jpg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-md-7">
                <h3>Software Development</h3>
                <p className="fst-italic">
                  At Premium IT Consultants, our engineers are the cream-of-the
                  crop. In fact, they all have engineering or computer science
                  degrees from reputed universities around the world. Then they
                  are always kept up-to-date on new techniques and methods
                  through continuous learning delivered by our US based
                  trainers. Our team is passionate about solving complex
                  problems quickly and effectively, helping our customers grow
                  their businesses, and providing the highest-quality customer
                  experience.
                </p>
              </div>
            </div>
            <div
              className="row gy-4 align-items-center features-item"
              data-aos="fade-up"
            >
              <div className="col-md-5 order-1 order-md-2">
                <img
                  src="assets/img/business.jpg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-md-7 order-2 order-md-1">
                <h3>IT & Business Solutions</h3>
                <p className="fst-italic">
                  Premium IT Consultants provides technological solutions to
                  address current and emerging business requirements of clients
                  who grow at the pace of technological innovation. Premium IT
                  Consultants’ solutions have been shaped by our deep knowledge
                  of emerging business trends, built by harnessing the prowess
                  in next generation technologies across Cloud, Analytics,
                  Mobility and Internet of Things (IoT) and backed by the
                  superior delivery experience, agile execution philosophies and
                  a key focus on quality.
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};
export default Service;
