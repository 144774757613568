import { useEffect } from 'react';

const Navigation = ({ home, about, service, contact }) => {
  const toggleMenu = () => {
    let nav = document.getElementById('header');

    nav.classList.toggle('mobile-nav-active');

    const mobileNavShow = document.getElementsByClassName('mobile-nav-show')[0];
    const mobileNavHide = document.getElementsByClassName('mobile-nav-hide')[0];

    mobileNavShow.classList.toggle('d-none');
    mobileNavHide.classList.toggle('d-none');
  };

  return (
    <header id="header" className="header d-flex align-items-center fixed-top">
      <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
        <a href="/" className="logo d-flex align-items-center">
          <img src="assets/img/logomain.png" alt="" />
          {/* <h1>Premium IT Consultants</h1> */}
        </a>

        <i
          className="mobile-nav-toggle mobile-nav-show bi bi-list"
          onClick={() => toggleMenu()}
        ></i>
        <i
          className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"
          onClick={() => toggleMenu()}
        ></i>
        <nav id="navbar" className="navbar">
          <ul>
            <li>
              <a href="/" className={`${home}`}>
                Home
              </a>
            </li>
            <li>
              <a href="about-us" className={`${about}`}>
                About Us
              </a>
            </li>
            <li>
              <a href="Service" className={`${service}`}>
                Our Services
              </a>
            </li>

            <li>
              <a href="contact-us" className={`${contact}`}>
                Contact Us
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Navigation;
