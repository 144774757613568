const Footer = () => {
  return (
    <footer id="footer" className="footer">
      <div className="container">
        <div className="row gy-4">
          <div className="col-lg-5 col-md-12 footer-info">
            <a href="index.html" className="logo d-flex align-items-center">
              <span>Premium IT Consultants</span>
            </a>
            <p>Serving technology better</p>
            {/* <div className="social-links d-flex mt-4">
              <a href="#" className="whatsapp">
                <i className="bi bi-whatsapp"></i>
              </a>
              <a href="#" className="facebook">
                <i className="bi bi-facebook"></i>
              </a>
              <a href="#" className="youtube">
                <i className="bi bi-youtube"></i>
              </a>
              <a href="#" className="linkedin">
                <i className="bi bi-linkedin"></i>
              </a>
            </div> */}
          </div>

          <div className="col-lg-2 col-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="about-us">About us</a>
              </li>
              <li>
                <a href="contact-us">Contact Us</a>
              </li>
              <li>
                <a href="service">Services</a>
              </li>
              <li>
                <a href="privacy-policy">Privacy policy</a>
              </li>
            </ul>
          </div>

          <div className="col-lg-2 col-6 footer-links">
            <h4>Our Services</h4>
            <ul>
              <li>
                <a href="Service">IT Strategy Consultancy</a>
              </li>
              <li>
                <a href="Service">IT Managed Services</a>
              </li>
              <li>
                <a href="Service">Workforce Management</a>
              </li>
              <li>
                <a href="Service">Software Development</a>
              </li>
              <li>
                <a href="Service">IT & Business Solutions</a>
              </li>
            </ul>
          </div>

          <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
            <h4>Contact Us</h4>
            <p>
              NJ, New Brunswick - Plaza II <br></br>
              317 George Street
              <br></br>
              Suite 320
              <br></br>
              New Brunswick<br></br>
              NJ 08901<br></br>
              <strong>Phone:</strong> (908) 720-3362<br></br>
              <strong>Email:</strong> admin@premiumconsultants.com
              <br></br>
            </p>
          </div>
        </div>
      </div>

      <div className="container mt-4">
        <div className="copyright">
          &copy; Copyright 2023 {}
          <strong>
            <span>Premium IT Consultants</span>
          </strong>
          . All Rights Reserved
        </div>
      </div>
    </footer>
  );
};

export default Footer;
