import Footer from "./sub/footer";
import Navigation from "./sub/navigation";

const About = () => {
  return (
    <>
      <Navigation about="active" />
      <main id="main">
        <div className="breadcrumbs">
          <div
            className="page-header d-flex align-items-center"
            style={{ "background-image": "url(assets/img/service.jpg)" }}
          >
            <div className="container position-relative">
              <div className="row d-flex justify-content-center">
                <div className="col-lg-6 text-center">
                  <h2>Consulting is the secret to unlocking your vision.</h2>
                </div>
              </div>
            </div>
          </div>
          <nav>
            <div className="container">
              <ol>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>About Us</li>
              </ol>
            </div>
          </nav>
        </div>

        <section id="about" className="about">
          <div className="container" data-aos="fade-up">
            <div className="row gy-4">
              {/* <div className="col-lg-6 position-relative align-self-start order-lg-last order-first">
                <img src="assets/img/about.jpg" className="img-fluid" alt="" />
                <a
                  href="https://www.youtube.com/watch?v=LXb3EKWsInQ"
                  className="glightbox play-btn"
                ></a>
              </div> */}
              <div className="  content order-last  order-lg-first ">
                <h3>About Us</h3>
                <p>
                  Premium IT Consultants is an Information Technology company
                  founded in the United States of America to provide consulting
                  services on IT and recruitment. Premium IT Consultants is
                  dedicated to offer products and services across the globe such
                  as IT & Business solutions with software, hardware; in-house
                  software development; IT managed services; workforce
                  management, IT consultancy in Python, Java, Tableau, Business
                  Intelligence (BI) tools, mobile networks, broadband networks.
                </p>
                <ul>
                  <li data-aos="fade-up" data-aos-delay="100">
                    <i className="bi bi-diagram-3"></i>
                    <div>
                      <h5>Vision Statement</h5>
                      <p>
                        The vision of Premium IT Consultants is to be a global
                        industry leader in providing innovative technology
                        solutions through IT consulting services.
                      </p>
                    </div>
                  </li>
                  <li data-aos="fade-up" data-aos-delay="200">
                    <i className="bi bi-fullscreen-exit"></i>
                    <div>
                      <h5> Mission Statement</h5>
                      <p>
                        The Mission of Premium IT Consultants is to provide
                        end-to-end creative solutions*, IT managed services and
                        talent placement, cutting-edge training in the fields of
                        emerging technology and innovation and develop software.
                        Using state of the art technology and high-quality
                        trainers, we help develop leaders of tomorrow. End to
                        end solution refer to complete package of software &
                        hardware supply to the customers in design, integration,
                        testing, setup & operations phases.
                      </p>
                    </div>
                  </li>
                  <li data-aos="fade-up" data-aos-delay="300">
                    <i className="bi bi-broadcast"></i>
                    <div>
                      <h5> Strength</h5>
                      <p>
                        EWe have a professional, industry experienced team.
                        Together, we bring more than 20 years of experience
                        gained in different parts of the world. Our key
                        strengths include customer-centric services with
                        experienced professionals from the IT industries and
                        high-quality trainers from the reputed academic
                        institutions. We identify talents by providing
                        consulting services and place them in industry
                        internship or job markets internationally through our
                        affiliated business partners. We develop strong
                        relationships with our clients based on honesty,
                        integrity and sheer dedication. We focus on quality
                        services & solutions rather than quantity to satisfy our
                        customers in due time.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default About;
