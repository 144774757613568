import Footer from "./sub/footer";
import Navigation from "./sub/navigation";
import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { useState } from "react";

const Result = () => {
  return (
    <p style={{ color: "green" }}>Your message has been sent successfully.</p>
  );
};
const Contact = () => {
  const form = useRef();
  const [result, showResult] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_vkkik1a",
        "template_2hpxit3",
        form.current,
        "VvncC_zmTTiWi-pKP"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    showResult(true);
  };
  return (
    <>
      <Navigation contact="active" />

      <main id="main">
        <div className="breadcrumbs">
          <div
            className="page-header d-flex align-items-center"
            style={{ "background-image": "url(assets/img/contact.jpg)" }}
          >
            <div className="container position-relative">
              <div className="row d-flex justify-content-center">
                <div className="col-lg-6 text-center">
                  <h2>Contact</h2>
                  <p>
                    if you are passionate about technology and want to work with
                    us then please contact us at the following address.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <nav>
            <div className="container">
              <ol>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Contact Us</li>
              </ol>
            </div>
          </nav>
        </div>

        <section id="contact" className="contact">
          <div className="container" data-aos="fade-up">
            <div>
              {/* <iframe
                style="border:0; width: 100%; height: 340px;"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621"
                frameborder="0"
                allowfullscreen
              ></iframe> */}
            </div>

            <div className="row gy-4 mt-4">
              <div className="col-lg-4">
                <div className="info-item d-flex">
                  <i className="bi bi-geo-alt flex-shrink-0"></i>
                  <div>
                    <h4>Location:</h4>
                    <p>
                      NJ, New Brunswick - Plaza II <br></br> 317 George Street
                      Suite 320 New Brunswick NJ 08901
                    </p>
                  </div>
                </div>

                <div className="info-item d-flex">
                  <i className="bi bi-envelope flex-shrink-0"></i>
                  <div>
                    <h4>Email:</h4>
                    <p>admin@premiumconsultants.com</p>
                  </div>
                </div>

                <div className="info-item d-flex">
                  <i className="bi bi-phone flex-shrink-0"></i>
                  <div>
                    <h4>Phone:</h4>
                    <p>(908) 720-3362</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-8">
                <form
                  ref={form}
                  onSubmit={sendEmail}
                  className="php-email-form"
                >
                  <div className="row">
                    <div className="form-title">
                      <h2 style={{ color: "#0e1d34" }}>
                        LEAVE A MESSAGE FOR US
                      </h2>
                    </div>
                    <div className="col form-group">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        placeholder="Your Name"
                        required
                      />
                    </div>
                    <div className="col form-group">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Your Email"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      id="subject"
                      placeholder="Subject"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      name="message"
                      rows={5}
                      placeholder="Message"
                      required
                      defaultValue={""}
                    />
                  </div>
                  <div className="my-3">
                    <div className="loading">Loading</div>
                    <div className="error-message" />
                    <div className="sent-message">
                      Your message has been sent. Thank you!
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="submit">Send Message</button>
                  </div>
                  <div className="row message-success">
                    {result ? <Result /> : null}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default Contact;
