import Footer from "./sub/footer";
import Navigation from "./sub/navigation";

function Policy() {
  return (
    <>
      <Navigation />
      <section id="hero" className="privacy">
        <div className="container">
          <div class="row">
            <div class="col-lg-12">
              <section id="service" className="services pt-0">
                <div className="container" data-aos="fade-up">
                  <div className="section-header">
                    <span>Privacy Policy</span>
                    <h2>Privacy Policy</h2>
                  </div>
                </div>
              </section>
              <div className="row">
                <div className="col-lg-12 content">
                  <div className="">
                    <p className="policy-text">
                      As part of Premium IT Consultants long tradition of trust,
                      the confidentiality of personal and business information
                      is paramount. We maintain high standards in order to
                      safeguard personal information. We will remain vigilant in
                      protecting that information and in using it in a fair and
                      lawful manner.
                    </p>
                  </div>
                </div>
              </div>
              <h3 className="policy-color">
                To what does this Online Privacy Statement apply?
              </h3>
              <p className="policy-text">
                This Online Privacy Statement applies to the personal and
                business information that we collect from or about you via this{" "}
                <a href="https://www.premiumitconsultants.com/">
                  premiumitconsultants.com
                </a>{" "}
                web site (this “Site”). This Site may contain links to other web
                sites, and this Online Privacy Statement does not apply to those
                sites. If you link to any of those sites, we recommend that you
                review their privacy policies. If we change our privacy policies
                or practices, we will also change this Online Privacy Statement.
              </p>
              <h3 className="policy-color">
                What personal and technical information do we collect via this
                Site?
              </h3>
              <p className="policy-text">
                This Site will collect personal and business information that
                you voluntarily provide, such as your name, address, telephone
                number or e-mail address. This Site may also collect information
                on your preferences, such as language selection; information on
                which pages within this Site you visit; and certain technical
                information regarding your computer and operating systems, such
                as your Internet protocol address, domain name and system
                configurations and settings. We will not know who you are unless
                you tell us.
              </p>
              <h3 className="policy-color">
                How do we use, share and secure personal information that we
                collect via this Site?
              </h3>
              <p className="policy-text">
                The information you give us is absolutely safe with us. We will
                never rent, sell, share or otherwise disclose your personal
                information to third parties. We may, however, contact you
                periodically regarding your account or any service you have
                subscribed to. We may also send you promotional announcements
                from time to time about new products and services that you might
                find useful. You may opt out from receiving information from us
                at any time.
              </p>
              <h3 className="policy-color">How do we use cookies?</h3>
              <p className="policy-text">
                On this Site, we may use cookies or other technology to gather
                information about Site usage to help us improve the content,
                usability and functionality of this Site, and to remember your
                personal settings, such as language selection. This will help us
                understand how many new and repeat users visit this Site, which
                features or pages are most popular, and user browsing and usage
                patterns.
              </p>
              <h3 className="policy-color">
                What if you have your browser set with privacy preferences?
              </h3>
              <p className="policy-text">
                This Site may contain information, including Privacy Preference
                Information (“P3P”), intended to work with your selected privacy
                preferences. While we have tried to make such Privacy Preference
                Information included within this Site conform to this full-text
                Online Privacy Statement, this Online Privacy Statement is the
                definitive statement of privacy policies and practices for this
                Site.
              </p>
              {/* <h3 className="policy-color">Live Chat</h3>
              <p className="policy-text">
                We use live chat to assist you with questions about our
                products. If our live chat is offline we will collect your name
                and email address so that we may reply to your request.
              </p> */}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Policy;
