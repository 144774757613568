import { useEffect } from "react";
import Footer from "./sub/footer";
import Navigation from "./sub/navigation";

const Home = () => {
  return (
    <>
      <Navigation home="active" />

      <section id="hero" className="hero d-flex align-items-center">
        <div className="container">
          <div className="row gy-4 d-flex justify-content-between">
            <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
              <h2 data-aos="fade-up">
                Consulting is the secret to unlocking your vision.
              </h2>
              {/* <p data-aos="fade-up" data-aos-delay="100">
                Facere distinctio molestiae nisi fugit tenetur repellat non
                praesentium nesciunt optio quis sit odio nemo quisquam. eius
                quos reiciendis eum vel eum voluptatem eum maiores eaque id
                optio ullam occaecati odio est possimus vel reprehenderit
              </p> */}
            </div>

            <div
              className="col-lg-5 order-1 order-lg-2 hero-img"
              data-aos="zoom-out"
            >
              <img
                src="assets/img/hero-img.svg"
                className="img-fluid mb-3 mb-lg-0"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section id="featured-services" className="featured-services">
        <div className="container">
          <div className="row gy-4">
            <div className="section-header">
              <span>About Us</span>
              <h2>About Us</h2>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ======= About Us Section ======= --> */}
      <section id="about" className="about pt-0">
        <div className="container" data-aos="fade-up">
          <div className="row gy-4">
            <div className="col-lg-6 position-relative align-self-start order-lg-last order-first">
              <img src="assets/img/about.jpg" className="img-fluid" alt="" />
              <a
                // href="https://www.youtube.com/watch?v=LXb3EKWsInQ"
                className="glightbox play-btn"
              ></a>
            </div>
            <div className="col-lg-6 content order-last  order-lg-first">
              <h3>About Us</h3>
              <p>
                Premium IT Consultants is an Information Technology company
                founded in the United States of America to provide consulting
                services on IT and recruitment.
              </p>
              <ul>
                <li data-aos="fade-up" data-aos-delay="100">
                  <i className="bi bi-diagram-3"></i>
                  <div>
                    <h5>Vision</h5>
                    <p>
                      The vision of Premium IT Consultants is to be a global
                      industry leader in providing innovative technology
                      solutions through IT consulting services.
                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay="200">
                  <i className="bi bi-fullscreen-exit"></i>
                  <div>
                    <h5>Mission</h5>
                    <p>
                      The Mission of Premium IT Consultants is to provide
                      end-to-end creative solutions*, IT managed services and
                      talent placement, cutting-edge training in the fields of
                      emerging technology and innovation and develop software.
                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay="300">
                  <i className="bi bi-broadcast"></i>
                  <div>
                    <h5>Strength</h5>
                    <p>
                      We have a professional, industry experienced team.
                      Together, we bring more than 20 years of experience gained
                      in different parts of the world.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Us Section --> */}

      {/* <!-- ======= Services Section ======= --> */}
      <section id="service" className="services pt-0">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <span>Our Services</span>
            <h2>Our Services</h2>
          </div>

          <div className="row gy-4">
            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/img/strategy.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    IT Strategy Consultancy
                  </a>
                </h3>
                <p>
                  Strategic consultancy, where our professionals guide
                  corporations to identify right technology and implementation
                  those technologies in a cost-effective manner.
                </p>
              </div>
            </div>
            <div className="col-lg-4 " data-aos="fade-up" data-aos-delay="500">
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/img/security.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    IT Security Services
                  </a>
                </h3>
                <p>
                  To gain external, objective advice and recommendations,to gain
                  access to our consultants' specialized expertise.
                </p>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/img/management.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    IT Managed Services
                  </a>
                </h3>
                <p>
                  IT Managed services is the practice of outsourcing on a
                  proactive basis certain information technology processes and
                  functions intended to improve operations and cut expenses.
                </p>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/img/workplace.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    Workforce Management
                  </a>
                </h3>
                <p>
                  Premium IT Consultants recognizes that irrespective of the
                  size of the company, workforce management is a challenge.
                </p>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/img/software.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    Software Development
                  </a>
                </h3>
                <p>
                  At Premium IT Consultants, our engineers are the cream-of-the
                  crop. In fact, they all have engineering or computer science
                  degrees from reputed universities around the world.
                </p>
              </div>
            </div>

            <div className="col-lg-4 " data-aos="fade-up" data-aos-delay="500">
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/img/business.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    IT & Business Solutions
                  </a>
                </h3>
                <p>
                  Premium IT Consultants provides technological solutions to
                  address current and emerging business requirements of clients
                  who grow at the pace of technological innovation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Services Section --> */}
      <Footer />
    </>
  );
};

export default Home;
